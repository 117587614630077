import React, { useEffect, useState, useMemo } from "react";
import { toast } from "sonner";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import instance from "../utils/axiosConfig";
import {
  useWallet,
  WalletProvider,
} from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  WalletActionButton,
  WalletConnectButton,
  WalletDisconnectButton,
  WalletModalProvider,
  WalletSelectButton,
} from "@tronweb3/tronwallet-adapter-react-ui";

const Signup = () => {
  const rows = [
    { name: "Multi Action Button", reactUI: WalletActionButton },
  ];

  const [data, setData] = useState({});
  const [reflink, setReflink] = useState("");

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const location = useLocation();
  const { address, connected, wallet } = useWallet();
  useEffect(() => {
    let match = location.search.match(/[\?&]reflink=([^&]+)/);
    if (match && match[1]) {
      const reflinkValue = match[1];
      setReflink(reflinkValue);
    } else {
      console.log("RefLink not found in the input string");
    }
  }, []);

  const registerUser = async (e) => {
    e.preventDefault();
    const { name, email, phone, refferal, password } = data;
    if (!name || !email || !phone || !password ) {
      toast.error("Please fill all the fields");
      return;
    }
    if(!address){
      toast.error("Please connect your wallet");
      return;
    }
    try {
      let registerPromise;
      if (reflink) {
        registerPromise = instance.post(`/api/register?reflink=${reflink}`, {
          fullname: name,
          email,
          contactNumber: phone,
          refId: refferal,
          walletAddress:address,
          password,
        });
      } else {
        registerPromise = instance.post("/api/register", {
          fullname: name,
          email,
          contactNumber: phone,
          refId: refferal,
          walletAddress:address,
          password,
        });
      }

      toast.promise(registerPromise, {
        loading: "Registering...",
        success: (data) => {
          return `${data.data.message} Registered !!!`;
        },
        error: "Error in Registering. Please try again.",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="contact-form-wrap w-50">
          <h1 className="text-center mb-10">SIGN-UP USER</h1>
          <form action="#">
            <div className="row">
              <div className="col-md-12">
                <div className="form-grp">
                  <input
                    type="text"
                    placeholder="Enter your Name"
                    name="name"
                    value={data.name}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-grp">
                  <input
                    type="email"
                    placeholder="Enter you email"
                    name="email"
                    value={data.email}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-grp">
                  <input
                    type="number"
                    placeholder="Enter your Phone number"
                    name="phone"
                    value={data.phone}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-grp">
                  <input
                    type="text"
                    placeholder="Enter you Refferal"
                    name="refferal"
                    disabled={reflink ? true : false}
                    value={reflink ? reflink : data.refferal}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
              
              <div className="col-md-12">
                <div className="form-grp">
                  <input
                    type="password"
                    placeholder="Enter you Password"
                    name="password"
                    value={data.password}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-4">
              {rows.map((row, index) => {
                
                return (
                  <div key={index} className="row">
                    <div className="col-md-12">
                      <div className="form-grp">
                      <row.reactUI></row.reactUI>
                      {address}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="submit-btn text-center d-flex justify-content-between">
              <button
                type="submit"
                className="btn"
                onClick={(e) => registerUser(e)}
              >
                Signup
              </button>

              <button type="submit" className="btn">
                <Link to="/login">Login</Link>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Signup;
