import React from 'react'

const TeamArea = () => {
  return (
    <>
        <section className="team-area pt-130">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6">
                        <div className="section-title text-center mb-70">
                            <span className="sub-title">OUr team</span>
                            <h2 className="title">The Leadership <br/> <span>Team</span></h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-3 col-md-4 col-sm-6">
                        <div className="team-item">
                            <div className="team-thumb">
                                <img src="assets/img/team/team_img01.png" alt=""/>
                            </div>
                            <div className="team-content">
                                <h2 className="title">Cameron Williamson</h2>
                                <span>Founder & CO</span>
                                <ul className="team-social">
                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-4 col-sm-6">
                        <div className="team-item">
                            <div className="team-thumb">
                                <img src="assets/img/team/team_img02.png" alt=""/>
                            </div>
                            <div className="team-content">
                                <h2 className="title">Eleanor Pena</h2>
                                <span>Head of Design</span>
                                <ul className="team-social">
                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-4 col-sm-6">
                        <div className="team-item">
                            <div className="team-thumb">
                                <img src="assets/img/team/team_img03.png" alt=""/>
                            </div>
                            <div className="team-content">
                                <h2 className="title">Bessie Cooper</h2>
                                <span>Vp People</span>
                                <ul className="team-social">
                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-4 col-sm-6">
                        <div className="team-item">
                            <div className="team-thumb">
                                <img src="assets/img/team/team_img04.png" alt=""/>
                            </div>
                            <div className="team-content">
                                <h2 className="title">Darlene Robertson</h2>
                                <span>Product Manager</span>
                                <ul className="team-social">
                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </>
  )
}

export default TeamArea