import React from 'react'

const AboutArea = () => {
  return (
    <>
         <section id="about" className="about-area pt-130 pb-130">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
                            <img src="assets/img/logo/logo4.png" alt=""/>
                            <img src="assets/img/images/about_img02.png" alt="" className="img-two"/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-content wow fadeInRight" data-wow-delay=".2s">
                            <div className="section-title mb-30">
                                <span className="sub-title">Who we are</span>
                                <h2 className="title"> <span>Tronmaster</span> <br/> Your Trusted Shield in the tronmaster</h2>
                            </div>
                            <p>Tronmaster is a community based crowd funding platform that allows individuals to earn cryptocurrencies through various methods.
We offer passive income opportunities to users by allowing them to earn cryptocurrencies without actively trading or investing.</p>
                            <a href="#" className="btn">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AboutArea