import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://tron-master.onrender.com', // Replace with your API base URL
  timeout: 5000, // Timeout for requests in milliseconds
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;