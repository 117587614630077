import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Banner from "./components/Banner";
import AboutArea from "./components/AboutArea";
import PartnerArea from "./components/PartnerArea";
import ChooseArea from "./components/ChooseArea";
import ChartArea from "./components/ChartArea";
import RoadMap from "./components/RoadMap";
import Footer from "./components/Footer";
import Document from "./components/Document";
import TeamArea from "./components/TeamArea";
import ContactArea from "./components/ContactArea";
import Profile from "./pages/Profile";
import { Routes, Route } from "react-router-dom";
import Admin from "./pages/Admin";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import { Toaster, toast } from "sonner";
import { useEffect, useState,useMemo } from "react";
import {
  WalletDisconnectedError,
  WalletNotFoundError,
} from "@tronweb3/tronwallet-abstract-adapter";
import {
  useWallet,
  WalletProvider,
} from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  WalletActionButton,
  WalletConnectButton,
  WalletDisconnectButton,
  WalletModalProvider,
  WalletSelectButton,
} from "@tronweb3/tronwallet-adapter-react-ui";

import {
  BitKeepAdapter,
  OkxWalletAdapter,
  TokenPocketAdapter,
  TronLinkAdapter,
} from "@tronweb3/tronwallet-adapters";
import { WalletConnectAdapter } from "@tronweb3/tronwallet-adapter-walletconnect";
import { tronWeb } from "./tronweb.js";
import { LedgerAdapter } from "@tronweb3/tronwallet-adapter-ledger";
import { Button } from "@tronweb3/tronwallet-adapter-react-ui";

function App() {
  function onError(e) {
    if (e instanceof WalletNotFoundError) {
      toast.error(e.message);
    } else if (e instanceof WalletDisconnectedError) {
      toast.error(e.message);
    } else toast.error(e.message);
  }
  const adapters = useMemo(function () {
    const tronLinkAdapter = new TronLinkAdapter();
    const walletConnectAdapter = new WalletConnectAdapter({
      network: "tron",
      options: {
        relayUrl: "wss://relay.walletconnect.com",
        // example WC app project ID
        projectId: "5fc507d8fc7ae913fff0b8071c7df231",
        metadata: {
          name: "Tron Manager",
          description: "JustLend WalletConnect",
          url: "https://your-dapp-url.org/",
          icons: ["https://your-dapp-url.org/mainLogo.svg"],
        },
      },
      web3ModalConfig: {
        themeMode: "dark",
        themeVariables: {
          "--w3m-z-index": "1000",
        },
      },
    });
    const ledger = new LedgerAdapter({
      accountNumber: 2,
    });
    const bitKeepAdapter = new BitKeepAdapter();
    const tokenPocketAdapter = new TokenPocketAdapter();
    const okxwalletAdapter = new OkxWalletAdapter();
    return [
      tronLinkAdapter,
      bitKeepAdapter,
      tokenPocketAdapter,
      okxwalletAdapter,
      walletConnectAdapter,
      ledger,
    ];
  }, []);
  return (
    <>
      <WalletProvider
        onError={onError}
        autoConnect={true}
        disableAutoConnectOnLoad={true}
        adapters={adapters}
      >
        <WalletModalProvider>
          <Toaster position="top-right" duration={1500} expand={true} />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Navbar />
                  <main className="fix">
                    <Banner />
                    <AboutArea />
                    {/* <PartnerArea /> */}
                    <ChooseArea />
                    {/* <ChartArea /> */}
                    {/* <div className="area-bg">
          <RoadMap />
          <Document />
        </div> */}
                    <TeamArea />
                    <ContactArea />
                  </main>
                </>
              }
            />

            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/login" element={<Login />} />
          </Routes>
          <Footer />
        </WalletModalProvider>
      </WalletProvider>
    </>
  );
}

export default App;
