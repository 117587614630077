import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import instance from "../utils/axiosConfig";
const Login = () => {
  const [data, setData] = useState({});

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const loginUser = async(e)=>{
    e.preventDefault();
    if(!data.email || !data.password){
      toast.error("Please fill all the fields");
      return;
    }
    try {
      const loginPromise = instance.post("/api/login", {
        email: data.email,
        password: data.password,
      });
      toast.promise(loginPromise, {
        loading: "Sigin User...",
        success: (data) => {
          return `${data.data.message} Logged In !!!`;
        },
        error: "Error in Registering. Please try again.",
      });
      
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="contact-form-wrap w-50">
          <h1 className="text-center mb-10">LOGIN USER</h1>
          <form action="#">
            <div className="row">
              <div className="col-md-12">
                <div className="form-grp">
                  <input
                    type="email"
                    placeholder="Enter Your Email"
                    name="email"
                    value={data.email}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-grp">
                  <input
                    type="password"
                    placeholder="Enter your Password"
                    name="password"
                    value={data.password}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="submit-btn text-center d-flex justify-content-between">
              <button type="submit" className="btn" onClick={(e)=>loginUser(e)}>
                Login
              </button>
              <button type="submit" className="btn">
                <Link to="/signup">Signup</Link>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
