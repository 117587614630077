import React from 'react'

const Banner = () => {
  return (
    <>
        <section className="banner-area banner-bg">
            <div className="banner-shape-wrap">
                <img src="assets/img/banner/banner_shape01.png" alt="" className="img-one"/>
                <img src="assets/img/banner/banner_shape02.png" alt="" className="img-two"/>
                <img src="assets/img/banner/banner_shape03.png" alt="" className="img-three"/>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="banner-content text-center">
                            <h2 className="title">Join Best Crypto  <span>Crowd Funding</span> Community Program</h2>
                        </div>
                        {/* <div className="banner-progress-wrap">
                            <ul>
                                <li>Pre Sell</li>
                                <li>Soft Cap</li>
                                <li>Bonus</li>
                            </ul>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: "75%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <h4 className="title">65% target raised <span>1 ETH = $1000 = 3177.38 CIC</span></h4>
                        </div> */}
                    </div>
                </div>
                
            </div>
        </section> 
    </>
  )
}

export default Banner