import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
const Navbar = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <header id="header">
        <div id="header-fixed-height"></div>
        <div id="sticky-header" className="menu-area">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12 ">
                <div className="mobile-nav-toggler " onClick={()=>setShow(!show)}>
                  <i className="fas fa-bars mt-25"></i>
                </div>
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo">
                      <img src="assets/img/logo/logo3.png" alt="logo" />
                    </div>
                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                      <ul className="navigation">
                        <li className="active menu-item-has-children">
                          <a href="#header" className="section-link">
                            Home
                          </a>
                        </li>
                        <li>
                          <a href="#about" className="section-link">
                            About us
                          </a>
                        </li>
                        <li>
                          <a href="#contact" className="section-link">
                            Contact us
                          </a>
                        </li>
                       
                      </ul>
                    </div>
                    {show ? <div className="navbar-wrap main-menu d-flex d-lg-none">
                      <ul className="navigation">
                        <li className="active menu-item-has-children">
                          <a href="#header" className="section-link">
                            Home
                          </a>
                        </li>
                        <li>
                          <a href="#about" className="section-link">
                            About us
                          </a>
                        </li>
                        <li>
                          <a href="#contact" className="section-link">
                            Contact us
                          </a>
                        </li>
                        <li className="header-btn">
                        <button className="btn">
                          <Link to="/signup">Connect</Link>
                        </button>
                        </li>
                      </ul>
                    </div> : null}
                    <div className="header-action d-none d-md-block">
                      <ul>
                        <li className="header-btn">
                        <button className="btn">
                          <Link to="/signup">Connect</Link>
                        </button>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>

                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn">
                      <i className="fas fa-times"></i>
                    </div>
                    <div className="nav-logo">
                      <a href="/">
                        <img src="assets/img/logo/logo.png" alt="" title="" />
                      </a>
                    </div>
                    <div className="menu-outer"></div>
                    <div className="social-links">
                      <ul className="clearfix">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop"></div>
              </div>
            </div>
          </div>
        </div>
      </header>

     
    </>
  );
};

export default Navbar;
