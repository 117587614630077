import React from "react";
import Card from "../utils/Card";
const cardDetails = [
  {
    id: 1,
    title: "100% Safe & secure",
    description: "Add new, trending and rare artwork to your collection.",
  },
  {
    id: 2,
    title: "No admin control",
    description: "Add new, trending and rare artwork to your collection.",
  },
  {
    id: 3,
    title: "100% distribution",
    description: "Add new, trending and rare artwork to your collection.",
  },
  {
    id: 4,
    title: "100% Transparency",
    description: "Add new, trending and rare artwork to your collection.",
  },
  {
    id: 5,
    title: "Instant withdrawal",
    description: "Add new, trending and rare artwork to your collection.",
  }
];
const ChooseArea = () => {
  return (
    <>
      <section className="choose-area pb-130">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="section-title text-center mb-50">
                <span className="sub-title">why Choose us</span>
                <h2 className="title">
                Why choose  <span>Tronmaster?</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row choose-active">
            {cardDetails.map((card, index) => (
              <Card
                title={card.title}
                id={card.id}
                description={card.description}
              />
            ))}
          </div>
          <div
            className="slide-progress"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span className="slider__label sr-only"></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChooseArea;
