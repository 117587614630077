import React, { useState } from "react";
import { toast } from "sonner";
const Profile = () => {
  const refCode = "Abcdefg";

  const copyUrl = async () => {
    try {
      await navigator.clipboard.writeText(
        `http://localhost:3000/signup/${refCode}`
      );
      toast.info("URL copied to clipboard");
    } catch (error) {
      console.error("Unable to copy URL to clipboard", error);
    }
  };

  const dashboardData = [
    {
      id: 1,
      title: "Self Business",
      icon: "fa-solid fa-user",
      value: "400",
      url: "www.tronmaster.one",
    },
    {
      id: 2,
      title: "Second Business",
      icon: "fa-solid fa-chart-bar",
      value: "300",
      url: "www.example.com",
    },
    {
      id: 3,
      title: "Another Business",
      icon: "fa-solid fa-briefcase",
      value: "700",
      url: "www.business.com",
    },
    {
      id: 4,
      title: "New Business",
      icon: "fa-solid fa-coins",
      value: "800",
      url: "www.newbiz.com",
    },
    {
      id: 5,
      title: "Tech Business",
      icon: "fa-solid fa-laptop",
      value: "600",
      url: "www.techbiz.com",
    },
    {
      id: 6,
      title: "Creative Business",
      icon: "fa-solid fa-paint-brush",
      value: "900",
      url: "www.creativebiz.com",
    },
    {
      id: 7,
      title: "Finance Business",
      icon: "fa-solid fa-wallet",
      value: "1200",
      url: "www.financebiz.com",
    },
    {
      id: 8,
      title: "Online Business",
      icon: "fa-solid fa-globe",
      value: "500",
      url: "www.onlinebiz.com",
    },
  ];

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <nav class="col-md-2 d-none d-md-block bg-[#09172A] sidebar">
            <div class="sidebar-sticky ">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <div className="logo">
                    <img src="assets/img/logo/logo3.png" alt="logo" />
                  </div>
                </li>
                <li class="nav-item ">
                  <a class="nav-link active" href="#">
                    Dashboard <span class="sr-only">(current)</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    My Account
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Activation Fund Manager
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Incomes
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Wallet
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <main
            role="main"
            class="col-md-9 ml-sm-auto mt-md-4 col-lg-10 px-md-4"
          >
            <div class="dashboard-header">
              <h1 class="h2">
                {" "}
                <i class="fa-solid fa-user fa-1x text-info "></i> Neeraj Choubisa
              </h1>
              <input
                type="text"
                hidden={true}
                name="url"
                value={`http://localhost:3000/signup/${refCode}`}
                readOnly
              />
              <code>
                {" "}
                {`http://localhost:3000/signup/${refCode}`}{" "}
                <i onClick={copyUrl} class="fa-solid fa-1x fa-copy"></i>
              </code>
            </div>

            <div class="row">
              {dashboardData.map((data) => {
                return (
                  <div class="col-xl-4 col-sm-6 col-12 mb-4">
                    <div class="card bg-dark">
                      <div class="card-content">
                        <div class="card-body">
                          <div class="media d-flex justify-content-between">
                            <div class="align-self-center">
                              <i class={`fa-3x text-warning ${data.icon} `}></i>
                            </div>
                            <div class="media-body text-end">
                              <h3>{data.value}</h3>
                              <span>{data.title}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Profile;
