import React from "react";

const Card = ({ title, id, description }) => {
  return (
    <>
      <div className="col-lg-3">
        <div className="choose-item">
          <div className="choose-icon">
            <img src={`assets/img/icon/choose_icon0${id%4}.svg`} alt="" />
          </div>
          <div className="choose-content">
            <h2 className="title">
              <a href="#">{title}</a>
            </h2>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
