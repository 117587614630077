import React from "react";

const Admin = () => {
  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <nav class="col-md-2 d-none d-md-block bg-[#09172A] sidebar">
            <div class="sidebar-sticky ">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <div className="logo">
                    <img src="assets/img/logo/logo3.png" alt="logo" />
                  </div>
                </li>
                <li class="nav-item ">
                  <a class="nav-link active" href="#">
                   Hello, Neeraj Choubisa <span class="sr-only">(current)</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Orders
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Products
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Customers
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <main
            role="main"
            class="col-md-9 ml-sm-auto mt-md-4 col-lg-10 px-md-4"
          >
            <div class="dashboard-header">
              <h1 class="h2">Admin Dashboard</h1>
              <p>This is a simple dashboard.</p>
            </div>

            <div class="row">
              <div class="col-xl-4 mb-4 col-sm-6 col-12">
                <div class="card bg-dark">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-flex justify-content-between">
                        <div class="align-self-center">
                          <i class="fab fa-3x text-primary fa-youtube "></i>
                        </div>
                        <div class="media-body text-end">
                          <h3>278</h3>
                          <span>New Posts</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 mb-4 col-sm-6 col-12">
                <div class="card bg-dark">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-flex justify-content-between">
                        <div class="align-self-center">
                          <i class="fab fa-3x text-warning fa-youtube "></i>
                        </div>
                        <div class="media-body text-end">
                          <h3>156</h3>
                          <span>New Comments</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 mb-4 col-sm-6 col-12">
                <div class="card bg-dark">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-flex justify-content-between">
                        <div class="align-self-center">
                          <i class="fab fa-3x text-warning fa-youtube "></i>
                        </div>
                        <div class="media-body text-end">
                          <h3>156</h3>
                          <span>New Comments</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 mb-4 col-sm-6 col-12">
                <div class="card bg-dark">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-flex justify-content-between">
                        <div class="align-self-center">
                          <i class="fab fa-3x text-warning fa-youtube "></i>
                        </div>
                        <div class="media-body text-end">
                          <h3>156</h3>
                          <span>New Comments</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-4 col-sm-6 col-12">
                <div class="card bg-dark mb-4">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-flex">
                        <div class="media-body text-left">
                          <h3 class="primary">278</h3>
                          <span>New Posts</span>
                        </div>
                        <div class="align-self-center">
                          <i class="icon-book-open primary font-large-2 float-right"></i>
                        </div>
                      </div>
                      <div class="progress mt-1 mb-0" style={{ height: "7px" }}>
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "80%" }}
                          aria-valuenow="80"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-sm-6 col-12">
                <div class="card bg-dark mb-4">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-flex">
                        <div class="media-body text-left">
                          <h3 class="warning">156</h3>
                          <span>New Comments</span>
                        </div>
                        <div class="align-self-center">
                          <i class="icon-bubbles warning font-large-2 float-right"></i>
                        </div>
                      </div>
                      <div class="progress mt-1 mb-0" style={{ height: "7px" }}>
                        <div
                          class="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: "35%" }}
                          aria-valuenow="35"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-sm-6 col-12">
                <div class="card bg-dark mb-4">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-flex">
                        <div class="media-body text-left">
                          <h3 class="success">64.89 %</h3>
                          <span>Bounce Rate</span>
                        </div>
                        <div class="align-self-center">
                          <i class="icon-cup success font-large-2 float-right"></i>
                        </div>
                      </div>
                      <div class="progress mt-1 mb-0" style={{ height: "7px" }}>
                        <div
                          class="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "60%" }}
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-sm-6 col-12">
                <div class="card bg-dark mb-4">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-flex">
                        <div class="media-body text-left">
                          <h3 class="danger">423</h3>
                          <span>Total Visits</span>
                        </div>
                        <div class="align-self-center">
                          <i class="icon-direction danger font-large-2 float-right"></i>
                        </div>
                      </div>
                      <div class="progress mt-1 mb-0" style={{ height: "7px" }}>
                        <div
                          class="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "40%" }}
                          aria-valuenow="40"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Admin;
